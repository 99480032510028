<template>
  <section
    class="users-page h-page-sm md:h-page overflow-y-auto bg-white dark:bg-dark-bgSecondary rounded-xl shadow-2xl p-4 md:p-5 lg:p-10"
  >
    <router-view />
  </section>
</template>

<script>
export default {
  name: "Users",
};
</script>
